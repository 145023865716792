<template>
  <div>
    <v-row v-if="allowManageProjects">
      <v-col>
        <v-btn
          :ripple="false"
          :to="{name: 'project-add'}"
          class="mt-1"
          color="primary"
          outlined
          rounded
        >
          {{ $t('Create project') }}
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="loading" class="py-16 text-center">
      <v-progress-circular color="primary" indeterminate />
    </div>
    <v-row v-else>
      <v-col
        v-for="project in projects"
        :key="`pr-${project.id}`"
        cols
        lg="3"
        md="4"
        sm="6"
        xl="2"
      >
        <v-card class="d-flex flex-column" flat height="100%">
          <v-card-title>
            <router-link
              :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'active'}}"
              class="text--primary text-body-1 project-title"
            >
              {{ project.name }}
            </router-link>
            <v-spacer />
            <v-btn
              v-if="allowManageProjects"
              :ripple="false"
              :to="{name: 'project-edit', params: {projectId: project.id}}"
              icon
            >
              <v-icon v-text="icons.edit" />
            </v-btn>
          </v-card-title>
          <v-card-subtitle>
            <app-date :value="project.created_at" />
          </v-card-subtitle>
          <v-card-text class="flex-grow-1">
            <div class="mb-2">
              <div v-if="project.depositsCount.registered" class="d-inline-block mr-4">
                <v-chip
                  :ripple="false"
                  :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'active'}}"
                  class="mr-2 text-decoration-none"
                  color="success"
                  elevation="0"
                  outlined
                >
                  {{ project.depositsCount.registered }}
                </v-chip>
                <router-link
                  :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'active'}}"
                >{{ $t('Registered', {count: project.depositsCount.registered}) }}
                </router-link>
              </div>
              <div v-if="project.depositsCount.inProgress" class="d-inline-block">
                <v-chip
                  :ripple="false"
                  :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'active'}}"
                  class="mr-2 text-decoration-none"
                  color="primary"
                  elevation="0"
                  outlined
                >
                  {{ project.depositsCount.inProgress }}
                </v-chip>
                <router-link
                  :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'active'}}"
                >{{ $t('In progress', {count: project.depositsCount.inProgress}) }}
                </router-link>
              </div>
            </div>
            <div v-if="project.depositsCount.draft">
              <v-chip
                :ripple="false"
                :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'drafts'}}"
                class="mr-2 text-decoration-none"
                color="text-lighten1"
                elevation="0"
                outlined
              >
                {{ project.depositsCount.draft }}
              </v-chip>
              <router-link
                :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'drafts'}}"
              >{{ $t('Drafts', {count: project.depositsCount.draft}) }}
              </router-link>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex align-center pa-4">
            {{ profile.organization.name }}
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {mdiAccount, mdiCog} from '@mdi/js'
import AppDate from '@/components/AppDate'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'ProjectsListBusiness',
  components: {AppDate},
  mixins: [error401handler],
  data () {
    return {
      loading: false,
      icons: {
        avatar: mdiAccount,
        edit: mdiCog,
      }
    }
  },
  computed: {
    ...mapState({
      locale: state => state.locale,
      profile: state => state.profile,
      projects: state => state.projectsBusiness?.projects,
      projectsLoaded: state => state.projectsBusiness?.projectsLoaded,
    }),
    allowManageProjects () {
      return this.profile.role === 'manager'
    },
  },
  async mounted () {
    this.loading = true
    try {
      await this.loadProjects()
    } catch (e) {
      this.handleError(e)
    } finally {
      this.loading = false
    }
  },
  methods: mapActions({
    loadProjects: 'projectsBusiness/prLoad',
  })
}
</script>

<style lang="scss" scoped>
  .project-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
