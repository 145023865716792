<template>
  <div>
    <v-row>
      <v-col>
        <project-dialog-add-edit key="prj-dialog-add" />
      </v-col>
    </v-row>

    <div v-if="loading" class="py-16 text-center">
      <v-progress-circular color="primary" indeterminate />
    </div>
    <v-row v-else>
      <v-col
        v-for="project in projects"
        :key="`pr-${project.id}`"
        cols
        lg="3"
        md="4"
        sm="6"
        xl="2"
      >
        <v-card class="d-flex flex-column" flat height="100%">
          <v-card-title>
            <router-link
              :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'active'}}"
              class="text--primary text-body-1 project-title"
            >
              {{ project.name }}
            </router-link>
            <v-spacer />
            <project-dialog-add-edit
              :key="`prj-dialog-${project.id}`"
              :project="project"
            />
          </v-card-title>
          <v-card-subtitle>
            <app-date :value="project.created_at" />
          </v-card-subtitle>
          <v-card-text class="flex-grow-1">
            <div class="mb-2">
              <div v-if="project.depositsCount.registered" class="d-inline-block mr-4">
                <v-chip
                  :ripple="false"
                  :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'active'}}"
                  class="mr-2 text-decoration-none"
                  color="success"
                  elevation="0"
                  outlined
                >
                  {{ project.depositsCount.registered }}
                </v-chip>
                <router-link
                  :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'active'}}"
                >{{ $t('Registered', {count: project.depositsCount.registered}) }}
                </router-link>
              </div>
              <div v-if="project.depositsCount.inProgress" class="d-inline-block">
                <v-chip
                  :ripple="false"
                  :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'active'}}"
                  class="mr-2 text-decoration-none"
                  color="primary"
                  elevation="0"
                  outlined
                >
                  {{ project.depositsCount.inProgress }}
                </v-chip>
                <router-link
                  :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'active'}}"
                >{{ $t('In progress', {count: project.depositsCount.inProgress}) }}
                </router-link>
              </div>
            </div>
            <div v-if="project.depositsCount.draft">
              <v-chip
                :ripple="false"
                :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'drafts'}}"
                class="mr-2 text-decoration-none"
                color="text-lighten1"
                elevation="0"
                outlined
              >
                {{ project.depositsCount.draft }}
              </v-chip>
              <router-link
                :to="{name: 'deposits', params: {projectId: project.id, sectionId: 'drafts'}}"
              >{{ $t('Drafts', {count: project.depositsCount.draft}) }}
              </router-link>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex align-center pa-4">
            <app-avatar :image="project.user.avatar" class="mr-2" size="22" />
            <div>{{ project.user.name }}</div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {mdiAccount} from '@mdi/js'
import AppAvatar from '@/components/AppAvatar'
import AppDate from '@/components/AppDate'
import ProjectDialogAddEdit from '@/components/ProjectDialogAddEdit'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'ProjectsListIndividual',
  components: {AppAvatar, AppDate, ProjectDialogAddEdit},
  mixins: [error401handler],
  data () {
    return {
      loading: false,
      icons: {
        avatar: mdiAccount,
      }
    }
  },
  computed: mapState({
    projects: state => state.projects?.projects,
    projectsLoaded: state => state.projects?.projectsLoaded,
  }),
  async mounted () {
    this.loading = true
    try {
      await this.loadProjects()
    } catch (e) {
      this.handleError(e)
    } finally {
      this.loading = false
    }
  },
  methods: mapActions({
    loadProjects: 'projects/prLoad',
  })
}
</script>

<style lang="scss" scoped>
  .project-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
