<template>
  <div>
    <div class="deposits-list">
      <div class="list-header deposit-icon" />
      <div class="list-header deposit-title text-caption font-weight-bold">
        {{ $t('Title') }}
      </div>
      <div class="list-header deposit-date text-center text-caption font-weight-bold">
        {{ $t('Date added') }}
      </div>
      <div class="list-header deposit-reg text-center text-caption font-weight-bold">
        {{ $t('Registration') }}
      </div>

      <div v-if="deposits.length === 0" class="no-deposits">
        <h3 v-show="!loading" class="grey--text">
          {{ $t('No deposits') }}
        </h3>
      </div>
      <template v-for="(deposit, i) in deposits">
        <div :key="`dp-icon-${deposit.id}`" class="deposit-row deposit-icon">
          <v-tooltip bottom open-delay="300">
            <template v-slot:activator="{on, attrs}">
              <v-icon
                class="mr-4"
                v-bind="attrs"
                v-on="on"
                v-text="icons[deposit.type]"
              />
            </template>
            <span>{{ types[deposit.type].title }}</span>
          </v-tooltip>
        </div>

        <div :key="`dp-title-${deposit.id}`" class="deposit-row deposit-title">
          <div>
            <router-link
              :to="depositUrl(deposit.id)"
              class="black--text"
              v-text="deposit.name"
            />

            <template v-if="deposit.tags">
              <br class="d-block d-md-none">
              <div class="d-inline ml-md-4">
                <v-chip
                  v-for="(tag, ti) in deposit.tags"
                  :key="`deposit-${deposit.id}-tag-${ti}`"
                  class="deposit-tag mb-0"
                  color="background"
                  label
                  small
                >
                  {{ tag }}
                </v-chip>
              </div>
            </template>

            <template v-if="['in_progress', 'registered'].includes(deposit.status)">
              <br class="d-block d-md-none">
              <deposit-link-ipchain
                v-if="deposit.ipchain && deposit.ipchain.IPChainLink"
                :deposit="deposit"
                class="d-inline-block ml-md-4 mr-2"
                x-small
              />
              <deposit-link-certificate
                v-if="deposit.ipchain && deposit.ipchain.certificate_link"
                :deposit="deposit"
                class="d-inline-block"
                x-small
              />
            </template>
          </div>
          <p class="mb-0 text--secondary overflow-hidden body-2">
            {{ deposit.description }}
          </p>
          <p v-if="commitHash(i)" class="mt-1 mb-0 text--secondary body-3">
            commit {{ commitHash(i) }}
          </p>
        </div>

        <div :key="`dp-date-${deposit.id}`" class="deposit-row deposit-date">
          <div class="d-inline d-sm-none body-2 font-weight-bold">
            {{ $t('Date added') }}:
          </div>
          <app-date :value="deposit.created_at" class="body-2" />
        </div>

        <div :key="`dp-reg-${deposit.id}`" class="deposit-row deposit-reg">
          <deposit-row-registration :deposit="deposit" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {mdiArchive, mdiCog, mdiMonitorScreenshot, mdiScriptTextOutline} from '@mdi/js'
import {mdiFigmaOutline} from '@/assets/mdi-custom-icons'
import AppDate from '@/components/AppDate'
import DepositLinkCertificate from '@/components/DepositLinkCertificate'
import DepositLinkIpchain from '@/components/DepositLinkIpchain'
import DepositRowRegistration from '@/components/DepositRowRegistration'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'DepositsList',
  components: {
    AppDate,
    DepositLinkCertificate,
    DepositLinkIpchain,
    DepositRowRegistration,
  },
  mixins: [error401handler],
  props: {
    deposits: {type: Array, required: true},
    loading: {type: Boolean, default: false},
  },
  data () {
    return {
      icons: {
        implementation: mdiScriptTextOutline,
        algorithm: mdiCog,
        screens: mdiMonitorScreenshot,
        design: mdiFigmaOutline,
        project: mdiArchive,
      }
    }
  },
  computed: {
    ...mapState({
      isBusinessAccount: state => state.profile.accountType === 'business',
      types: state => state.config.deposit?.types || {},
    }),
    ...mapGetters({
      editablePrv: 'deposits/editable',
      editableBiz: 'depositsBusiness/editable',
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    editable () {
      return this.isBusinessAccount ? this.editableBiz : this.editablePrv
    },
  },
  methods: {
    depositUrl (depositId) {
      return {
        name: this.editable(depositId) ? 'deposit-edit' : 'deposit-details',
        params: {depositId, projectId: this.$route.params.projectId}
      }
    },
    commitHash (index) {
      return this.deposits[index].files?.[0]?.meta?.commitHash
    }
  }
}
</script>

<style lang="scss" scoped>
.deposits-list {
  display: grid;
  grid-auto-rows: auto;
  margin: 0 -0.5 * $grid-gutter;
  grid-template-columns: 48px 1fr auto;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    margin: 0;
    grid-template-columns: 48px 1fr auto auto;
    row-gap: 0.25rem;
  }

  & > .no-deposits {
    background-color: var(--v-surface-base);
    grid-column: auto / span 4;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      border-radius: $border-radius-small;
    }
  }

  & > .list-header {
    display: none;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      display: block;
      padding: 0 0.5 * $grid-gutter;
    }
  }

  & > .deposit-row {
    background-color: var(--v-surface-base);
    padding: 0.5 * $grid-gutter;

    &:nth-child(4n + 1) {
      grid-column: auto / span 1;
    }
    &:nth-child(4n + 2) {
      grid-column: auto / span 2;
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        grid-column: auto / span 1;
      }
    }
    &:nth-child(4n + 3) {
      grid-column: auto / span 2;
      margin-bottom: 0.25rem;
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        grid-column: auto / span 1;
        margin-bottom: 0;
      }
    }
    &:nth-child(4n) {
      grid-column: auto / span 1;
      margin-bottom: 0.25rem;
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        margin-bottom: 0;
      }
    }

    &.deposit-icon {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        border-top-left-radius: $border-radius-small;
        border-bottom-left-radius: $border-radius-small;
      }
    }

    &.deposit-title {
    }

    &.deposit-date {
      padding-top: 6 * $spacer;
    }

    &.deposit-reg {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        border-top-right-radius: $border-radius-small;
        border-bottom-right-radius: $border-radius-small;
      }
    }
  }
}
</style>
