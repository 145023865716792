<template>
  <div>
    <v-container>
      <app-link-back-to-deposits
        :text="$t('Back to projects')"
        :to="{name: 'projects'}"
      />
      <h1>{{ $t('Create project') }}</h1>
    </v-container>

    <v-form v-if="profileLoaded" v-model="valid">
      <v-container class="deposit-container pb-0 pb-sm-3">
        <v-row class="mx-n3 mx-sm-0" no-gutters>
          <v-col>
            <v-card
              :ripple="false"
              class="px-3 px-sm-6 py-sm-3 px-md-12 py-md-6"
              flat
            >
              <form-project-business
                v-if="project"
                :all-teammates="allTeammates"
                :disabled="saving"
                :project="project"
                :server-feedback="serverFeedback"
                @input="input"
              />
              <v-row>
                <v-col class="d-flex align-start justify-end flex-wrap" lg="7">
                  <v-btn
                    :ripple="false"
                    :to="{name: 'projects'}"
                    outlined
                    rounded
                  >
                    {{ $t('Cancel') }}
                  </v-btn>
                  <v-btn
                    :disabled="saving"
                    :loading="saving"
                    :ripple="false"
                    class="ml-5"
                    color="primary"
                    elevation="0"
                    rounded
                    @click="submit"
                  >
                    {{ $t('Create') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import AppLinkBackToDeposits from '@/components/AppLinkBackToDeposits'
import FormProjectBusiness from '@/components/FormProjectBusiness'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'ProjectAdd',
  components: {FormProjectBusiness, AppLinkBackToDeposits},
  mixins: [error401handler],
  data () {
    return {
      project: {
        name: '',
        teammates: [],
      },
      serverFeedback: {
        name: null
      },
      valid: true,
      saving: false,
    }
  },
  computed: {
    ...mapState({
      profileLoaded: state => state.loaders.profile.status,
      allTeammates: state => state.profile.organization?.teammates,
    }),
  },
  methods: {
    ...mapMutations({
      showNotify: 'notify/show',
    }),
    ...mapActions({
      createProject: 'projectsBusiness/prCreate',
    }),
    input (value) {
      this.project = value
    },
    clearErrors () {
      for (let key of Object.keys(this.serverFeedback)) {
        this.serverFeedback[key] = null
      }
    },
    async submit () {
      this.saving = true
      this.clearErrors()
      let project = {
        name: this.project.name,
        teammates: this.project.teammates
          .filter(tm => {
            return tm.user.contributionWeight &&
              tm.user.contributionWeight !== '0'
          })
          .map(tm => ({
            account_id: tm.account_id,
            contributionWeight: tm.user.contributionWeight,
          }))
      }
      try {
        await this.createProject(project)
        this.showNotify({
          text: this.$t('ProjectCreated', {title: project.name}),
          color: 'success'
        })
        await this.$router.push({name: 'projects'})
      } catch (e) {
        if (!this.handleError(e) && e.response?.status === 422) {
          this.serverFeedback = {...e.response.data.message}
        }
      } finally {
        this.saving = false
      }
    },
  },
}
</script>
