<template>
  <div>
    <v-overlay :value="loading" opacity="0" absolute>
      <v-progress-circular color="primary" indeterminate />
    </v-overlay>

    <template v-if="!loading">
      <v-container>
        <app-link-back-to-deposits
          :text="$t('Back to projects')"
          :to="{name: 'projects'}"
        />
        <h1>
          {{ $t('Edit') }}
          {{ project.name }}
        </h1>
      </v-container>

      <v-form v-if="profileLoaded && projectSource" v-model="valid">
        <v-container class="deposit-container pb-0 pb-sm-3">
          <v-row class="mx-n3 mx-sm-0" no-gutters>
            <v-col>
              <v-card
                :ripple="false"
                class="px-3 px-sm-6 py-sm-3 px-md-12 py-md-6"
                flat
              >
                <form-project-business
                  :all-teammates="allTeammates"
                  :disabled="saving || removing"
                  :project="project"
                  :server-feedback="serverFeedback"
                  @input="input"
                />
                <v-row>
                  <v-col class="d-flex align-start justify-end flex-wrap" lg="7">
                    <v-btn
                      :disabled="saving"
                      :loading="removing"
                      :ripple="false"
                      class="mb-3"
                      color="grey"
                      rounded
                      outlined
                      @click="remove"
                    >
                      {{ $t('Delete project') }}
                    </v-btn>
                    <v-btn
                      :disabled="removing"
                      :loading="saving"
                      :ripple="false"
                      class="ml-5"
                      color="primary"
                      elevation="0"
                      rounded
                      @click="submit"
                    >
                      {{ $t('Save changes') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {cloneDeep} from 'lodash'
import FormProjectBusiness from '@/components/FormProjectBusiness'
import AppLinkBackToDeposits from '@/components/AppLinkBackToDeposits'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'ProjectEdit',
  components: {AppLinkBackToDeposits, FormProjectBusiness},
  mixins: [error401handler],
  props: {
    projectId: {type: [Number, String], required: true},
  },
  data () {
    return {
      project: {},
      serverFeedback: {
        name: null
      },
      valid: true,
      loading: false,
      saving: false,
      removing: false,
    }
  },
  computed: {
    ...mapState({
      profileLoaded: state => state.loaders.profile.status,
      allTeammates: state => state.profile.organization?.teammates,
    }),
    ...mapGetters({
      getProjectById: 'projectsBusiness/getProjectById',
    }),
    projectSource () {
      return this.getProjectById(this.projectId)
    },
  },
  async created () {
    if (!this.projectSource) {
      this.loading = true
      try {
        await this.loadProjects()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    }

    if (this.projectSource) {
      this.project = cloneDeep(this.projectSource)
    } else {
      this.setError({
        mode: 'global',
        title: this.$t('Project not found'),
      })
    }
  },
  methods: {
    ...mapMutations({
      setError: 'error/set',
      showNotify: 'notify/show',
    }),
    ...mapActions({
      loadProjects: 'projectsBusiness/prLoad',
      updateProject: 'projectsBusiness/prUpdate',
      removeProject: 'projectsBusiness/prRemove',
    }),
    input (value) {
      this.project = value
    },
    clearErrors () {
      for (let key of Object.keys(this.serverFeedback)) {
        this.serverFeedback[key] = null
      }
    },
    async submit () {
      this.saving = true
      this.clearErrors()
      let project = {
        id: this.project.id,
        name: this.project.name,
        teammates: this.project.teammates
          .filter(tm => {
            return tm.user.contributionWeight &&
              tm.user.contributionWeight !== '0'
          })
          .map(tm => ({
            account_id: tm.account_id,
            contributionWeight: tm.user.contributionWeight,
          }))
      }
      try {
        await this.updateProject(project)
        this.showNotify({
          text: this.$t('ProjectUpdated', {title: project.name}),
          color: 'success'
        })
        await this.$router.push({name: 'projects'})
      } catch (e) {
        if (!this.handleError(e) && e.response?.status === 422) {
          this.serverFeedback = {...e.response.data.message}
        }
      } finally {
        this.saving = false
      }
    },
    async remove () {
      let title = this.project.name
      this.removing = true
      try {
        await this.removeProject(this.project.id)
        this.showNotify({
          text: this.$t('ProjectDeleted', {title}),
          color: 'warning',
          timeout: 15000
        })
        await this.$router.push({name: 'projects'})
      } catch (e) {
        if (!this.handleError(e) && e.response?.status === 422) {
          this.serverFeedback = {...e.response.data.message}
        }
      } finally {
        this.removing = false
      }
    },
  },
}
</script>
